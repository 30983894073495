<div
  class="flex w-full flex-row rounded-md border-[2px] border-solid border-grey-300 p-[10px]"
  [ngClass]="{
    'border-grey-200': type === NoticeType.INFO,
    'bg-grey-100': type === NoticeType.INFO,
    'border-warning-200': type === NoticeType.WARNING,
    'bg-warning-100': type === NoticeType.WARNING
  }"
>
  <div class="flex min-w-[30px] justify-start">
    <icon
      class="h-[18px] w-[18px]"
      [svgIcon]="icon"
      [ngClass]="{
        'text-grey-500': type === NoticeType.INFO,
        'text-warning-500': type === NoticeType.WARNING
      }"
    ></icon>
  </div>
  <div class="flex flex-col">
    <h6 *ngIf="!!title" class="font-semibold text-word-mark-800">
      {{ title }}
    </h6>
    <p class="mb-0 text-word-mark-600">
      {{ text }}
    </p>
    <a
      *ngIf="!!href && !!hrefLabel"
      [target]="hrefTarget"
      [href]="href"
      class="mt-[5px] max-w-fit font-semibold text-word-mark-800"
      >{{ hrefLabel }}</a
    >
    <button *ngIf="!!buttonText" (click)="clicked.emit()" class="btn btn-info btn-sm mt-1 w-fit" type="button">
      {{ buttonText }}
    </button>
    <ng-content select="button"></ng-content>
  </div>
</div>
