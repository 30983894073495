import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '@app/+authenticated/shared/icon.component';
import { TranslateModule } from '@ngx-translate/core';

import { NoticeTypes } from './notice.model';

@Component({
  selector: 'sb-notice-old',
  templateUrl: './notice.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, TranslateModule, NgClass, NgIf],
})
export class OldNoticeComponent {
  public NoticeType = NoticeTypes;

  @Input()
  public type: NoticeTypes = NoticeTypes.INFO;
  @Input()
  public title?: string;
  @Input()
  public text?: string;
  @Input()
  public hrefLabel?: string;
  @Input()
  public href?: string;
  @Input()
  public hrefTarget?: '_self' | '_blank' | '_parent' | '_top' = '_blank';
  @Input()
  public buttonText?: string;
  @Output()
  public clicked = new EventEmitter<void>();
  public get icon() {
    switch (this.type) {
      case NoticeTypes.WARNING:
        return 'warning';
      default:
        return 'info';
    }
  }
}
